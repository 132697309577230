import React from 'react';

import { destroy } from '../Cart/storage';
import { withCart } from '../Cart/context';

import { Bold, Regular, TextColour } from '../../utils/variables';

import './summary.css';

import loadComponents from '../Loadable';
import { CheckoutButton, EmptyButton } from './placeholders';

const Button = loadComponents('button');
const ButtonHidden = loadComponents('button-hidden');

export default withCart(function BasketSummary({ total, updateTotal }) {
  const clearCart = () => {
    destroy();
    updateTotal();
  };
  return (
    <div className="basketSummary" style={summaryStyle}>
      <div className="basketTotal">
        <span style={summarySubHeader}>Cart Subtotal</span>
        <span style={summaryTotal}>
          &pound;{parseFloat(Math.round(total * 100) / 100).toFixed(2)}
        </span>
      </div>
      <div className="basketActions">
        <Button to="/checkout/" fallback={<CheckoutButton />} primary>
          Proceed to Checkout
        </Button>
        <ButtonHidden
          onClick={() => clearCart()}
          style={{
            marginTop: `1.875rem`,
            fontWeight: Regular,
            color: TextColour,
          }}
          fallback={<EmptyButton />}
        >
          Empty Basket
        </ButtonHidden>
      </div>
    </div>
  );
});

const summaryStyle = {
  fontWeight: Bold,
};

const summarySubHeader = {
  textTransform: `uppercase`,
  fontSize: `0.875rem`,
  marginBottom: `15px`,
};

const summaryTotal = {
  fontSize: `2.5rem`,
  lineHeight: 1,
  marginBottom: `2.8125rem`,
};
